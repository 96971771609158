import React from "react";
import Button from "../../component/Button";
import "./Header.css";

function Header() {
  return (
    <div id="header" style={{ position: "relative" }}>
      <img
        className="img1"
        src={`${process.env.PUBLIC_URL}/images/Beranda/header-background.png`}
        alt="background"
      />
      <img
        className="img2"
        src={`${process.env.PUBLIC_URL}/images/Beranda/header-spotlight.png`}
        alt="spotlight"
      />
      <span style={{ width: "100%", zIndex: "2", position: "relative" }}>
        <Button name="Produk Kami" id="button-header" link="/produk" />
      </span>
    </div>
  );
}

export default Header;
