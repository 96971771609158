import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Beranda from "./pages/Beranda/Beranda";
import Produk from "./pages/Produk/Produk";
import Kemitraan from "./pages/Kemitraan/Kemitraan";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import TentangKami from "./pages/TentangKami/TentangKami";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="*" element={<Navigate to="/beranda" />} />
        <Route path="/beranda" element={<Beranda />} />
        <Route path="/produk" element={<Produk />} />
        <Route path="/kemitraan" element={<Kemitraan />} />
        <Route path="/tentang-kami" element={<TentangKami />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
