import Header from "./Header";
import List from "./List";
import "./Produk.css";

function Produk() {
  return (
    <div id="page-produk">
      <Header />
      <List />
    </div>
  );
}

export default Produk;
