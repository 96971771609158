import React, { useEffect } from "react";
import Button from "../../component/Button";
import "./Section1.css";
import SectionTitle from "../../component/SectionTitle";
import Animation from "../../component/Animation";

function Section1() {
  useEffect(() => {
    Animation(".section1-right", ".section1-left");
  }, []);

  return (
    <div id="section1">
      <div id="section1-container">
        <div className="section1-left">
          <img
            id="section1-woman"
            src={`${process.env.PUBLIC_URL}/images/Beranda/Section1.png`}
            alt="pic"
          ></img>
        </div>
        <div className="section1-right">
          <div className="section1-title">
            <div id="cerita" style={{ marginBottom: "5%" }}>
              <SectionTitle title="Cerita" />
              <div id="mizuee">
                <img
                  id="section1-cat"
                  src={`${process.env.PUBLIC_URL}/images/mizuee-white.png`}
                  alt="cat"
                />
              </div>
            </div>
            <div id="kami">
              <SectionTitle title="Kami" />
              <img
                id="section1-cat2"
                src={`${process.env.PUBLIC_URL}/images/mizuee-white.png`}
                alt="cat"
              />
            </div>
          </div>
          <p className="section1-content content">
            Mizuee adalah merek minuman yang mengusung konsep Jepang, lahir dari
            visi Michael dan Romario pada tahun 2023. Mizuee merupakan brand
            minuman ke-2 yang dibuat sebagai transformasi dari brand sebelumnya
            yaitu kopi Mikoho yang didirikan pada tahun 2019.
          </p>
          <div style={{ float: "right" }}>
            <Button name="Selengkapnya" link="/tentang-kami"></Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
