import React from "react";
import "./Button.css";
import { Link } from "react-router-dom";

function Button(props) {
  return (
    <Link to={props.link}>
      <div id="btnContainer">
        <button
          onClick={() => {
            window.scrollTo(0, 0);
            props.onClick && props.onClick();
          }}
          className="btnComponent"
          id={props.id}
          style={{
            margin: props.margin,
            backgroundColor: props.backgroundColor,
            color: props.color,
            width: props.width,
            height: props.height,
          }}
        >
          <span id="btnName">{props.name}</span>
        </button>
      </div>
    </Link>
  );
}

export default Button;
