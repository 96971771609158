import React from "react";
import Button from "../../component/Button";
import "./Section4.css";

function Section4() {
  return (
    <div id="section4">
      <div className="section4-content">
        <div style={{ margin: "auto" }}>
          <div style={{ margin: "-7% 0 5%" }}>Ingin Menjadi Partner Kami?</div>
          <Button
            name="Lihat Detail"
            backgroundColor="#ea6647"
            color="#fef7e5"
            link="/kemitraan"
          />
        </div>
      </div>
    </div>
  );
}

export default Section4;
