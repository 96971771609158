import "./SectionTitle.css";

function SectionTitle(props) {
  return (
    <h1 className="section-title" style={{ fontSize: props.size }}>
      {props.title}
    </h1>
  );
}

export default SectionTitle;
