import React, { useEffect } from "react";
import "./Visi.css";
import SectionTitle from "../../component/SectionTitle";
import Animation from "../../component/Animation";

function Visi(props) {
  useEffect(() => {
    Animation(".visi-content");
  }, []);

  return (
    <div id="visi">
      <div style={{ display: "flex" }} className="visi-content">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/TentangKami/visi.jpg`}
            id="visi-picture"
            alt="misi"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "40vw",
              backgroundColor: "#EA6647",
              borderRadius: "0 4vw 4vw 0",
              padding: "7%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              boxSizing: "content-box",
            }}
            id="visi-background"
          >
            <div
              style={{
                marginBottom: "7%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <h1 className="section-title visi-section-title">Visi Kami</h1>
            </div>
            <div className="content visi">{props.content}</div>
          </div>
        </div>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/TentangKami/leaf-right.png`}
        style={{
          height: "20vw",
          position: "absolute",
          right: "0",
          top: "62%",
        }}
        alt="leaf1"
      />
    </div>
  );
}

export default Visi;
