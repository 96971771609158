export function Animation(element1, element2, element3) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.target.classList.contains("show")) return;
      else if (entry.isIntersecting) {
        entry.target.classList.add("show");
      }
    });
  });

  const hiddenElements = document.querySelectorAll(element1);
  const hiddenElements2 = document.querySelectorAll(element2);
  const hiddenElements3 = document.querySelectorAll(element3);

  hiddenElements.forEach((el) => observer.observe(el));
  hiddenElements2.forEach((el) => observer.observe(el));
  hiddenElements3.forEach((el) => observer.observe(el));
}

export default Animation;
