import React, { useEffect, useState } from "react";
import Button from "../../component/Button";
import "./Section2.css";
import SectionTitle from "../../component/SectionTitle";
import Carousel from "../../component/Carousel/Carousel";
import Animation from "../../component/Animation";

function Section2() {
  const [produk, setProduk] = useState([{}]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://api.mizuee.web.id/all-products")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((jsonRes) => {
        setProduk(jsonRes);
        setLoading(false);
      });

    Animation(".section2-title", ".section2-slideshow", ".section2-button");
  }, []);

  return (
    <div id="section2">
      <div className="section2-title">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "2%",
          }}
        ></div>
        <div>
          <SectionTitle title="Produk Kami" />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "2%",
          }}
        ></div>
      </div>
      <div className="section2-content">
        <div className="section2-slideshow" style={{ marginBottom: "5vw" }}>
          {loading ? (
            <div className="loading">
              <img
                src={`${process.env.PUBLIC_URL}/images/loading.png`}
                style={{ height: "10vw" }}
                alt="loading"
              />
            </div>
          ) : (
            <Carousel
              array={produk}
              type="image"
              numberOfSlides="3"
              color="#FADDAF"
            />
          )}
        </div>
        <div className="section2-button">
          <Button name="Lihat Semua Produk" link="/produk" />
        </div>
      </div>
    </div>
  );
}

export default Section2;
