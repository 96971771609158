import React, { useEffect, useState } from "react";
import "./TentangKami.css";
import Header from "./Header";
import History from "./History";
import Visi from "./Visi";
import Misi from "./Misi";

function TentangKami() {
  const [misi, setMisi] = useState("");
  const [visi, setVisi] = useState("");
  const [sejarah, setSejarah] = useState("");

  useEffect(() => {
    fetch("https://api.mizuee.web.id/profil")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((jsonRes) => {
        setMisi(jsonRes[0].misi);
        setVisi(jsonRes[0].visi);
        setSejarah(jsonRes[0].sejarah);
      });
  }, []);

  return (
    <div id="page-tentang-kami">
      <Header />
      <History content={sejarah} />
      <Visi content={visi} />
      <Misi content={misi} />
    </div>
  );
}

export default TentangKami;
