import "./List.css";
import React, { useEffect, useState } from "react";

function List() {
  const categories = [
    "All",
    "Milk Based",
    "Tea & Fruit Based",
    "Coffee Based",
    "Food",
  ];
  const [dataProduk, setDataProduk] = useState([]);
  const [filteredDataProduk, setFilteredDataProduk] = useState([]);
  const [inputText, setInputText] = useState("");
  const [currentCategory, setCurrentCategory] = useState("All");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://api.mizuee.web.id/all-products")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((jsonRes) => {
        setDataProduk(jsonRes);
        setFilteredDataProduk(jsonRes);
        setLoading(false);
      });

    let menus = document.getElementsByClassName("filter-categories");
    for (var i = 0; i < menus.length; i++) {
      if (menus[i].classList.contains("list-active")) {
        menus[i].classList.toggle("list-active");
      }
      if (menus[i].id === "All") {
        menus[i].classList.add("list-active");
      }
    }

    for (let index = 0; index < menus.length; index++) {
      menus[index].addEventListener("click", printDetails);
    }
  }, []);

  useEffect(() => {
    if (inputText !== "") {
      if (currentCategory === "All") {
        const searched = dataProduk.filter((data) => {
          return data.name.toLowerCase().includes(inputText.toLowerCase());
        });
        setFilteredDataProduk(searched);
      } else {
        const temp = dataProduk.filter(
          (data) => data.category === currentCategory
        );
        const searched = temp.filter((data) => {
          return data.name.toLowerCase().includes(inputText.toLowerCase());
        });
        setFilteredDataProduk(searched);
      }
    } else {
      filterByCategory(currentCategory);
    }
  }, [inputText, currentCategory]);

  function printDetails(e) {
    let menus = document.getElementsByClassName("filter-categories");
    for (var i = 0; i < menus.length; i++) {
      if (menus[i].classList.contains("list-active")) {
        menus[i].classList.toggle("list-active");
      }
    }
    this.classList.add("list-active");
  }

  const filterByCategory = (category) => {
    if (category === "All") {
      setFilteredDataProduk(dataProduk);
    } else {
      let filtered = dataProduk.filter((data) => data.category === category);
      setFilteredDataProduk(filtered);
    }
  };

  const onSearch = (e) => {
    setInputText(e.target.value.trim().replace(/[^a-zA-Z0-9 ]/g, ""));
  };

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  return (
    <div id="list">
      <div className="search-wrapper">
        <div className="search-produk">Produk Kami</div>
        <div id="search-container">
          <span
            style={{
              position: "absolute",
              height: "100%",
              alignItems: "center",
              textAlign: "center",
              paddingLeft: "2.5%",
              display: "flex",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/search.png`}
              id="search-image"
              alt="search"
            />
          </span>
          <input
            type="text"
            placeholder="Cari Produk..."
            id="search"
            onChange={onSearch}
          />
        </div>
      </div>
      <div>
        {categories.map((category) => (
          <button
            className="filter-categories"
            id={category}
            onClick={() => {
              filterByCategory(category);
              setCurrentCategory(category);
            }}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="produk-list">
        {loading ? (
          <div className="loading">
            <img
              src={`${process.env.PUBLIC_URL}/images/loading.png`}
              style={{ height: "10vw" }}
              alt="loading"
            />
          </div>
        ) : filteredDataProduk.length > 0 ? (
          filteredDataProduk.map((data) => (
            <div className="produk-list-wrapper">
              <div style={{ height: "15vw" }}>
                <img src={data.image} alt="Unable to load" id="produk-image" />
              </div>
              <div className="produk-detail">
                <span id="produk-title">{data.name}</span>
                <span id="produk-description">
                  IDR {addCommas(removeNonNumeric(data.price))}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ marginBottom: "2%" }}></div>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "1.5vw",
                color: "#1f5c6fb1",
              }}
            >
              Produk Tidak Ditemukan
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default List;
