import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/beranda") {
      document.getElementById("beranda").style.color = "#ea6647";
    } else {
      document.getElementById("beranda").style.color = "#1f5c6f";
    }
  }, [location.pathname]);

  useEffect(() => {
    let menus = document.getElementsByClassName("nav-items");

    function printDetails(e) {
      for (var i = 0; i < menus.length; i++) {
        if (menus[i].classList.contains("navbar-active")) {
          menus[i].classList.toggle("navbar-active");
        }
      }
      this.classList.add("navbar-active");
    }

    for (var i = 0; i < menus.length; i++) {
      if (menus[i].classList.contains("navbar-active")) {
        menus[i].classList.toggle("navbar-active");
      }
      if (location.pathname.includes(menus[i].id)) {
        menus[i].classList.add("navbar-active");
      }
    }

    for (let index = 0; index < menus.length; index++) {
      menus[index].addEventListener("click", printDetails);
    }
  }, [location.pathname]);

  return (
    <div id="nav">
      <div className="nav-content">
        <Link to="/beranda" className="nav-links">
          <div className="nav">
            <div className="nav-items" id="beranda">
              Beranda
            </div>
          </div>
        </Link>
        <Link to="/produk" className="nav-links">
          <div className="nav">
            <div className="nav-items" id="produk">
              Produk
            </div>
          </div>
        </Link>
        <Link to="/beranda" className="nav-links" style={{ width: "5%" }}>
          <div className="nav">
            <div className="nav-items">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.png`}
                id="navbar-logo"
                alt=""
              ></img>
            </div>
          </div>
        </Link>
        <Link to="/kemitraan" className="nav-links">
          <div className="nav">
            <div className="nav-items" id="kemitraan">
              Kemitraan
            </div>
          </div>
        </Link>
        <Link to="/tentang-kami" className="nav-links">
          <div className="nav">
            <div className="nav-items" id="tentang-kami">
              Sejarah
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
