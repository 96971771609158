import React, { useEffect, useState } from "react";
import "./Form.css";
import SectionTitle from "../../component/SectionTitle";
import Animation from "../../component/Animation";

const Form = () => {
  const [validated, setValidated] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [emailWrongFormat, setEmailWrongFormat] = useState(false);
  const [namaEmpty, setNamaEmpty] = useState(false);
  const [namaWrongFormat, setNamaWrongFormat] = useState(false);
  const [noHpEmpty, setNoHpEmpty] = useState(false);
  const [noHpWrongFormat, setNoHpWrongFormat] = useState(false);
  const [pesanEmpty, setPesanEmpty] = useState(false);

  useEffect(() => {
    Animation(".form-kemitraan");
  }, []);

  useEffect(() => {
    if (validated === true) {
      let namaK =
        document.getElementById("namaK") !== null &&
        document.getElementById("namaK").value;
      let emailK =
        document.getElementById("emailK") !== null &&
        document.getElementById("emailK").value;
      let noHpK =
        document.getElementById("noHpK") !== null &&
        document.getElementById("noHpK").value;
      let pesanK =
        document.getElementById("pesanK") !== null &&
        document.getElementById("pesanK").value;

      let templateBody = `Nama: ${namaK} %0a
    Nomor Hp: ${noHpK} %0a
    Email: ${emailK} %0a
    Pesan: ${pesanK}`;

      window.open(
        `mailto:mizueeholic@gmail.com?subject=Kemitraan&body=${templateBody}`,
        "_self"
      );
    }
  }, [validated]);

  const validateNama = () => {
    const namaInput = document.getElementById("namaK").value.trim();
    const regexName = /^[A-Za-z]+$/;

    if (namaInput === "") {
      setNamaEmpty(true);
      setNamaWrongFormat(false);
      return false;
    } else if (!namaInput.match(regexName)) {
      setNamaEmpty(false);
      setNamaWrongFormat(true);
      return false;
    } else {
      setNamaEmpty(false);
      setNamaWrongFormat(false);
      return true;
    }
  };

  const validateEmail = () => {
    const emailInput = document.getElementById("emailK").value.trim();
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

    if (emailInput === "") {
      setEmailEmpty(true);
      setEmailWrongFormat(false);
      return false;
    } else if (!emailInput.match(regexEmail)) {
      setEmailEmpty(false);
      setEmailWrongFormat(true);
      return false;
    } else {
      setEmailEmpty(false);
      setEmailWrongFormat(false);
      return true;
    }
  };

  const validateNoHp = () => {
    const noHpInput = document.getElementById("noHpK").value.trim();
    const regexPhoneNumber = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/;

    if (noHpInput === "") {
      setNoHpEmpty(true);
      setNoHpWrongFormat(false);
      return false;
    } else if (!noHpInput.match(regexPhoneNumber)) {
      setNoHpEmpty(false);
      setNoHpWrongFormat(true);
      return false;
    } else {
      setNoHpEmpty(false);
      setNoHpWrongFormat(false);
      return true;
    }
  };

  const validatePesan = () => {
    const pesanInput = document.getElementById("pesanK").value.trim();

    if (pesanInput === "") {
      setPesanEmpty(true);
      return false;
    } else {
      setPesanEmpty(false);
      return true;
    }
  };

  const validateEmpty = () => {
    const isNamaEmpty = document.getElementById("namaK").value.trim() === "";
    const isEmailEmpty = document.getElementById("emailK").value.trim() === "";
    const isNoHpEmpty = document.getElementById("noHpK").value.trim() === "";
    const isPesanEmpty = document.getElementById("pesanK").value.trim() === "";

    setNamaEmpty(isNamaEmpty);
    setEmailEmpty(isEmailEmpty);
    setNoHpEmpty(isNoHpEmpty);
    setPesanEmpty(isPesanEmpty);

    if (isNamaEmpty || isEmailEmpty || isNoHpEmpty || isPesanEmpty) {
      return false;
    } else {
      return true;
    }
  };

  const validateFormat = () => {
    const isEmpty = validateEmpty();

    if (!isEmpty) {
      setValidated(false);
      return;
    }

    const isNamaValid = validateNama();
    const isEmailValid = validateEmail();
    const isNoHpValid = validateNoHp();
    const isPesanValid = validatePesan();

    if (isNamaValid && isEmailValid && isNoHpValid && isPesanValid) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleButtonClick = () => {
    validateFormat();
  };

  return (
    <div className="form-kemitraan">
      <div id="title-kemitraan">
        <SectionTitle title="Daftar Kemitraan" />
      </div>
      <div id="form-container">
        <div className="form-left">
          <img
            src={`${process.env.PUBLIC_URL}/images/TentangKami/fellas.jpg`}
            id="kemitraan-img"
            alt="fellas"
          />
          <div className="location-container">
            <img
              src={`${process.env.PUBLIC_URL}/images/Kemitraan/map-pin.png`}
              id="form-img"
              alt="map"
            ></img>
            <p className="kemitraan-location">
              Duta Harapan Indah Blok N no. 4, Kapuk Muara, Penjaringan, Jakarta
              Utara, 14460
            </p>
          </div>
        </div>
        <div className="form-right content">
          <label htmlFor="fname">
            Nama <span className="required">*</span>
          </label>
          <div className="inputBox">
            <input
              type="text"
              id="namaK"
              name="fname"
              placeholder="Masukkan nama-mu"
              required
            />
          </div>

          <div className="warning">
            {namaEmpty && (
              <span className="warning-text">* Nama tidak boleh kosong</span>
            )}
            {namaWrongFormat && (
              <span className="warning-text">* Format nama salah</span>
            )}
          </div>

          <label htmlFor="email">
            Email <span className="required">*</span>
          </label>
          <div className="inputBox">
            <input
              type="text"
              id="emailK"
              name="email"
              placeholder="Masukkan email-mu"
              required
            />
          </div>
          <div className="warning">
            {emailEmpty && (
              <span className="warning-text">* Email tidak boleh kosong</span>
            )}
            {emailWrongFormat && (
              <span className="warning-text">* Format email salah</span>
            )}
          </div>

          <label htmlFor="noHp">
            Nomor Telepon <span className="required">*</span>
          </label>
          <div className="inputBox">
            <input
              type="text"
              id="noHpK"
              name="noHp"
              placeholder="Masukkan no.telepon-mu"
              required
            />
          </div>
          <div className="warning">
            {noHpEmpty && (
              <span className="warning-text">
                * Nomor telepon tidak boleh kosong
              </span>
            )}
            {noHpWrongFormat && (
              <span className="warning-text">* Format nomor telepon salah</span>
            )}
          </div>

          <label htmlFor="pesan">
            Pesan <span className="required">*</span>
          </label>
          <div className="inputBox">
            <textarea
              id="pesanK"
              name="pesan"
              placeholder="&nbsp;Tuliskan pesan-mu disini"
              rows={10}
              cols={50}
              required
              style={{ height: "10vw", paddingTop: "0.5vw" }}
            ></textarea>
          </div>
          <div className="warning">
            {pesanEmpty && (
              <span className="warning-text">* Pesan tidak boleh kosong</span>
            )}
          </div>
          <div id="btnContainer btnForm">
            <button
              onClick={() => {
                handleButtonClick();
              }}
              className="btnComponent"
              style={{
                backgroundColor: "#ea6647",
                color: "#fef7e5",
                width: "100%",
              }}
            >
              <span id="btnName">Kirim</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
