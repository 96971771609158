import React from "react";
import Header from "./Header";
import Form from "./Form";

const Kemitraan = () => {
  return (
    <div id="page-kemitraan">
      <Header />
      <Form />
    </div>
  );
};

export default Kemitraan;
