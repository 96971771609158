import React, { useEffect } from "react";
import "./Header.css";
import Animation from "../../component/Animation";

function Header() {
  useEffect(() => {
    Animation(".header-tentang-kami", ".tagline-tentang-kami");
  }, []);

  return (
    <div className="header-tentang-kami">
      <div
        style={{
          width: "50%",
          display: "flex",
          alignItems: "end",
          paddingBottom: "13vw",
          lineHeight: "1.5",
        }}
        className="tagline-tentang-kami"
      >
        Telusuri <br /> Cerita
        <br /> Di Balik <br /> Mizuee
      </div>
      <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/TentangKami/header.jpg`}
          style={{ height: "75%", width: "42vw", objectFit: "cover" }}
          alt="header"
        />
      </div>
    </div>
  );
}

export default Header;
