import React, { useEffect } from "react";
import "./History.css";
import SectionTitle from "../../component/SectionTitle";
import Animation from "../../component/Animation";

function History(props) {
  useEffect(() => {
    Animation(".history-title", ".history-content");
  }, []);

  return (
    <div id="history">
      <div
        style={{ padding: "12% 0 6%", display: "flex" }}
        className="history-title"
      >
        <SectionTitle title="Sejarah Mizuee" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "2%",
          }}
        ></div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "2vw",
          padding: "3%",
          textAlign: "justify",
          fontFamily: "Poppins",
          fontWeight: "600",
          color: "#1f5c6f",
        }}
        className="history-content content"
      >
        {props.content}
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/images/cat.png`}
        style={{
          position: "absolute",
          right: "7vw",
          height: "15vw",
          marginTop: "5%",
        }}
        className="history-image"
        alt="cat"
      />
    </div>
  );
}

export default History;
