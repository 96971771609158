import React, { useEffect } from "react";
import "./Section3.css";
import Instafeed from "instafeed.js";
import SectionTitle from "../../component/SectionTitle";
import Animation from "../../component/Animation";

function Section3() {
  const checkId = document.getElementById("instafeed-container");
  var userFeed = new Instafeed({
    get: "user",
    target: "instafeed-container",
    resolution: "low_resolution",
    limit: 6,
    template: '<a href="{{link}}" target="_blank"><img src="{{image}}" /></a>',
    accessToken:
      "IGQWRNazNwM2lkMXVOSFBVX19IdUl6aUg5RnRyRy1lbUM1YmhROUFCal83M2ZAPcERtbzlvLUFpaUUtVlU2TE1rUGpSYjNLazdrd2tNY0ZAqNUMxTzdIS0V4VTBVblIxbjlRRXVWaDhBMjRvUFA4YTFqRHJYTnliTHcZD",
  });

  useEffect(() => {
    <script src="https://cdn.jsdelivr.net/gh/stevenschobert/instafeed.js@2.0.0rc1/src/instafeed.min.js"></script>;
    <script type="text/javascript">{userFeed.run()}</script>;

    Animation(".section3-content");
  }, [checkId]);

  return (
    <div id="section3">
      <a
        id="section3-title"
        href="https://instagram.com/mizueeholic_"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://cdn-icons-png.freepik.com/256/12617/12617671.png?semt=ais_hybrid"
          id="instagram"
        />
        <SectionTitle title="@mizueeholic_" />
      </a>
      <div className="section3-content">
        <div id="instafeed-container" />
      </div>
    </div>
  );
}

export default Section3;
