import React from "react";
import "./Header.css";
import SectionTitle from "../../component/SectionTitle";

const Header = () => {
  return (
    <div id="header-kemitraan">
      <div>
        <SectionTitle title="Kemitraan" />
      </div>
      <span>
        <p className="deskripsiKemitraan content">
          Bergabunglah dengan Mizuee! Kami mengundang Anda untuk menjadi bagian
          dari perjalanan kesuksesan bersama. Dengan bergabung dalam kemitraan
          ini, Anda akan memiliki akses ke peluang bisnis yang menarik dan
          dukungan penuh dari tim kami. Bersama-sama, kita dapat menciptakan
          peluang baru, berbagi pengetahuan, dan meningkatkan daya saing.
          Keberhasilan Anda adalah prioritas kami, dan kami siap untuk bekerja
          sama membangun hubungan kemitraan yang kokoh dan saling mendukung.
        </p>
      </span>
    </div>
  );
};

export default Header;
