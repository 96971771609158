import React, { useEffect } from "react";
import "./Misi.css";
import SectionTitle from "../../component/SectionTitle";
import Animation from "../../component/Animation";

function Misi(props) {
  var text = props.content.split("\n");
  useEffect(() => {
    Animation(".misi-content");
  }, []);

  return (
    <div id="misi">
      <div style={{ display: "flex" }} className="misi-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "40vw",
              backgroundColor: "#EA6647",
              borderRadius: "4vw 0 0 4vw",
              padding: "7%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              boxSizing: "content-box",
            }}
            id="misi-background"
          >
            <div
              style={{
                marginBottom: "7%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <h1 className="section-title misi-section-title">Misi Kami</h1>
            </div>
            {text.map((element) => (
              <div className="misi content">{element}</div>
            ))}
          </div>
        </div>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/TentangKami/misi.jpg`}
            id="misi-picture"
            alt="misi"
          />
        </div>
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/images/TentangKami/leaf-left.png`}
        style={{
          height: "22vw",
          position: "absolute",
          left: "0",
          top: "30%",
        }}
        alt="leaf2"
      />
    </div>
  );
}

export default Misi;
