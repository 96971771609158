import React from "react";
import "./Header.css";

function Header() {
  return (
    <div id="header-produk">
      <img src={`${process.env.PUBLIC_URL}/images/Produk/Header.png`} />
    </div>
  );
}

export default Header;
