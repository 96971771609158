import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div id="footer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: " 0 5%",
          bottom: "0",
          paddingTop: "27vw",
        }}
      >
        <div>
          <div style={{ fontFamily: "Rammetto One" }}>Hubungi Kami</div>
          <br />
          <div style={{ margin: "5% 0" }}>Mail: mizueeholic@gmail.com</div>
          <div>No telepon: +62 877-9888-2425</div>
        </div>
        <div>
          <br />
          <br />
          © 2023 PT. Bersama Ong <br /> <br />
          Sejahtera. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
